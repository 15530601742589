import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as styles from './BaseCollapse.module.scss';

type BaseCollapseProps = {
  header: React.ReactElement;
  isOpen: boolean;
} & React.HTMLProps<HTMLDivElement>;

const BaseCollapse: React.FC<BaseCollapseProps> = ({
  header, isOpen, children, className, ...props
}) => {
  const [height, setHeight] = useState<number>(0);
  const content = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkHeight = () => {
      if (content.current) setHeight(content.current.scrollHeight);
    };
    checkHeight();
    window.addEventListener('resize', checkHeight);
  });

  return (
    <div className={cn(styles.baseCollapse, className)} {...props}>
      <div>{header}</div>
      <div
        className={styles.baseCollapse__container}
        style={{ height: `${isOpen ? height : 0}px`, transitionDuration: `${Math.max(height, 300)}ms` }}
      >
        <div className={styles.baseCollapse__body} ref={content}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default BaseCollapse;
