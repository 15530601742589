import React, {memo, useState} from "react";
import cn from 'classnames';

import banner_1920 from 'src/assets/images/1920/webinar_banner_1920.png';

import tg_png from 'src/assets/images/tg_white.png';
import tg_webp from 'src/assets/images/tg_white.webp';

import * as styles from './SectionWebinar.module.scss';
import FormRegistration from "src/components/FormRegistration";
import RegistrationDone from "src/components/RegistrationDone/RegistrationDone";

const SectionWebinar: React.FC = () => {
  const [isShipment, setIsShipment] = useState(false);
  const [isPlay, setIsPlay] = useState(false);

  return (
    <section className={styles.sectionWebinar} id="record">
      <div className={styles.sectionWebinar__container}>
        <div className={styles.sectionWebinar__cell_left}>
          <aside className={cn(styles.sectionWebinar__cell, styles.sectionWebinar__cell_second)}>
            {!isShipment
              ? <FormRegistration toggleProps isShipment={setIsShipment}/>
              : <RegistrationDone isShipment={setIsShipment}/>
            }
          </aside>
        </div>
        <article className={cn(styles.sectionWebinar__cell, styles.sectionWebinar__cell_first)}>
          <div className={styles.sectionWebinar__banner}>
            <iframe
              className={styles.sectionWebinar__iframe}
              src="https://www.youtube.com/embed/l27n5dLKlWs"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <img
              onClick={() => setIsPlay(!isPlay)}
              className={cn(isPlay && styles.sectionWebinar__img_hidden, styles.sectionWebinar__img)}
              src={banner_1920}
              alt="Preview Video"
            />
          </div>
          <h3 className={styles.sectionWebinar__title}>Вебинар «Как&nbsp;за&nbsp;21&nbsp;день стартануть в&nbsp;профессии UX-дизайнера»</h3>
          <a target='_blank' href='https://t.me/pixel_map' className={styles.sectionWebinar__teacher}>
            <picture className={styles.sectionWebinar__avatar}>
              <source srcSet={tg_png} type="image/png" />
              <source srcSet={tg_webp} type="image/webp" />
              <img src={tg_png} alt={tg_png} />
            </picture>
            <div>
              <p className={styles.sectionWebinar__name}>Подписывайся в Телеграм</p>
              <p className={styles.sectionWebinar__info}>Будь в курсе всех событий!</p>
            </div>
          </a>
        </article>
      </div>
    </section>
  );
};

//<img className={styles.sectionWebinar__banner} src={banner_1920} alt="Рекламный банер вебинара"/>

export default memo(SectionWebinar);
