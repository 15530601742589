import React, { FC, memo, useState } from "react";
import cn from "classnames";
import * as styles from "./SimpleProgramCard.module.scss";
import BaseCollapse from "src/components/ui/BaseCollapse";
import Text from "src/components/ui/Text/Text";
import Badge from "src/components/ui/Badge";
import ButtonCollapse from "src/components/ui/ButtonCollapse/ButtonCollapse";
import ProgramStep from "src/components/Program/components/ProgramCard/components/ProgramStep";

interface Props {
  id: number,
  description: string,
  image: string,
  image_webp: string,
  className?: string,
}

const SimpleProgramCard: FC<Props> = ({ id, description, image, image_webp, className }) => {
  return (
    <div className={styles.simpleCard}>
      <picture className={styles.simpleCard__image}>
        <source srcSet={image_webp} type='image/webp' />
        <source srcSet={image} type='image/png' />
        <img src={image} alt={description} />
      </picture>
      <Text className={cn(className)} align={'Center'}>{description}</Text>
    </div>
  )
};

export default memo(SimpleProgramCard);
