// extracted by mini-css-extract-plugin
export var plusesCourseCard__block = "PlusesCourseCard-module--plusesCourseCard__block--3j5HK";
export var plusesCourseCard__block_small = "PlusesCourseCard-module--plusesCourseCard__block_small--2MnCD";
export var plusesCourseCard__block_middle = "PlusesCourseCard-module--plusesCourseCard__block_middle--FpsAL";
export var plusesCourseCard__blockColor_orange = "PlusesCourseCard-module--plusesCourseCard__blockColor_orange--AgP3B";
export var plusesCourseCard__blockColor_blue = "PlusesCourseCard-module--plusesCourseCard__blockColor_blue--3XOXr";
export var plusesCourseCard__picWrap = "PlusesCourseCard-module--plusesCourseCard__picWrap--2jxn0";
export var plusesCourseCard__block_big = "PlusesCourseCard-module--plusesCourseCard__block_big--14aaV";
export var plusesCourseCard__pic = "PlusesCourseCard-module--plusesCourseCard__pic--2Vl5b";
export var plusesCourseCard__textTitle = "PlusesCourseCard-module--plusesCourseCard__textTitle--349t8";
export var plusesCourseCard__textContent = "PlusesCourseCard-module--plusesCourseCard__textContent--3l5ls";