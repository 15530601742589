// extracted by mini-css-extract-plugin
export var animationFadeEnter = "PlusesCourse-module--animation-fade-enter--2uuoi";
export var animationFadeExit = "PlusesCourse-module--animation-fade-exit--2cGLl";
export var animationFadeEnterActive = "PlusesCourse-module--animation-fade-enter-active--1GRVr";
export var animationFadeExitActive = "PlusesCourse-module--animation-fade-exit-active--1fRxd";
export var animationScaleEnter = "PlusesCourse-module--animation-scale-enter--1nLdt";
export var animationScaleExit = "PlusesCourse-module--animation-scale-exit--32ZNq";
export var animationScaleEnterActive = "PlusesCourse-module--animation-scale-enter-active--10W1p";
export var animationScaleExitActive = "PlusesCourse-module--animation-scale-exit-active--1B1tA";
export var root = "PlusesCourse-module--root--1rYWt";
export var plusesCourse = "PlusesCourse-module--plusesCourse--1raWW";
export var plusesCourse__title = "PlusesCourse-module--plusesCourse__title--2-B8E";
export var plusesCourse__blocks = "PlusesCourse-module--plusesCourse__blocks--3bjIm";
export var plusesCourse__card = "PlusesCourse-module--plusesCourse__card--2KfNq";