import React, { FC, memo } from 'react';
import ScrollComp from "src/components/ui/ScrollComp/ScrollComp";

import targetAudienceData from "./TargetAudienceData"

const TargetAudience: FC = () => (
    <section>
      <ScrollComp title={'Особенно подойдет для'} content={targetAudienceData} />
    </section>
  )

export default memo(TargetAudience);
