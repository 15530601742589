import React, { FC , memo} from 'react';
import cn from 'classnames';

import * as styles from './Badge.module.scss';

import Text from 'src/components/ui/Text';

export enum BadgeVariant {
  GREY = 'grey',
  YELLOW = 'yellow',
  PINK = 'pink',
  PURPLE = 'purple',
  GREEN = 'green',
}

type Props = {
  variant?: BadgeVariant;
  className?: string;
  children: React.ReactNode;
}

const Badge: FC<Props> = ({ variant, children, className }) => (
  <div
    className={cn(
      styles.badge,
      className,
      {
        [styles[`badge_${variant}`]]: variant,
      }
    )}
  >
    <Text element={'span'} weight={'700'} className={styles.badge__text}>{children}</Text>
  </div>
);

export default memo(Badge);
