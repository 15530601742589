// extracted by mini-css-extract-plugin
export var successfulBid = "SuccessfulBid-module--successfulBid--2Wzbk";
export var successfulBid__cirles = "SuccessfulBid-module--successfulBid__cirles--x8NJT";
export var cirles = "SuccessfulBid-module--cirles--2aXNh";
export var successfulBid__contentWrapper = "SuccessfulBid-module--successfulBid__contentWrapper--1nyRp";
export var successfulBid__circle = "SuccessfulBid-module--successfulBid__circle--1As1B";
export var successfulBid__tgImage = "SuccessfulBid-module--successfulBid__tgImage--upwSn";
export var successfulBid__title = "SuccessfulBid-module--successfulBid__title--tpn3_";
export var successfulBid__description = "SuccessfulBid-module--successfulBid__description--2_Myw";
export var successfulBid__buttonBack = "SuccessfulBid-module--successfulBid__buttonBack--1BHOY";
export var successfulBid__buttonBackText = "SuccessfulBid-module--successfulBid__buttonBackText--L7fvn";
export var successfulBid__icon = "SuccessfulBid-module--successfulBid__icon--1X0PH";
export var icons = "SuccessfulBid-module--icons--1mSGL";
export var successfulBid__icon_small = "SuccessfulBid-module--successfulBid__icon_small--1_COc";
export var successfulBid__icon_first = "SuccessfulBid-module--successfulBid__icon_first--1ZqkQ";
export var successfulBid__icon_second = "SuccessfulBid-module--successfulBid__icon_second--2K_sT";
export var successfulBid__icon_third = "SuccessfulBid-module--successfulBid__icon_third--2HauP";
export var successfulBid__icon_fourth = "SuccessfulBid-module--successfulBid__icon_fourth--2r6R5";
export var successfulBid__icon_fifth = "SuccessfulBid-module--successfulBid__icon_fifth--2VIkk";
export var successfulBid__icon_sixth = "SuccessfulBid-module--successfulBid__icon_sixth--1ETv9";
export var successfulBid__icon_seventh = "SuccessfulBid-module--successfulBid__icon_seventh--1qiMD";