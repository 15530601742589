import * as styles from './CurseStep.module.scss';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Scene, Controller } from 'react-scrollmagic';
import Text from 'src/components/ui/Text'
import cn from 'classnames';

import man0_webp from 'src/assets/images/with-coffee-tablet.webp';
import man1_webp from 'src/assets/images/with-tablet.webp';
import man2_webp from 'src/assets/images/with-phone.webp';
import man3_webp from 'src/assets/images/fly-man.webp';
import item0_webp from 'src/assets/images/light-bulb.webp';
import item1_webp from 'src/assets/images/book-pen.webp';
import item2_webp from 'src/assets/images/megafon.webp';
import item3_webp from 'src/assets/images/dumbbell.webp';


import man0_png from 'src/assets/images/with-coffee-tablet.png';
import man1_png from 'src/assets/images/with-tablet.png';
import man2_png from 'src/assets/images/with-phone.png';
import man3_png from 'src/assets/images/fly-man.png';
import item0_png from 'src/assets/images/light-bulb.png';
import item1_png from 'src/assets/images/book-pen.png';
import item2_png from 'src/assets/images/megafon.png';
import item3_png from 'src/assets/images/dumbbell.png';
import ScrollComp, { ScrollCompType } from "src/components/ui/ScrollComp/ScrollComp";


const CONTENT: ScrollCompType[] = [
  {
    id: 0,
    title: 'Изучаете теорию',
    description: 'Мы не теоретики, а\u00a0практики. В\u00a0основу курса вложен многолетний практический опыт компании MST.',
    color: 'orange',
    man_webp: man0_webp,
    man_png: man0_png,
    item_webp: item0_webp,
    item_png: item0_png,
  },
  {
    id: 1,
    title: 'Закрепляете на практике',
    description: 'На курсе, выполняя задания и получая обратную связь от ментора, вы точно достигнете прогресса!',
    color: 'violet',
    man_webp: man1_webp,
    man_png: man1_png,
    item_webp: item1_webp,
    item_png: item1_png,
  },
  {
    id: 2,
    title: 'Получаете и даёте обратную связь',
    description: 'Кроме обратной связи от\u00a0ментора, вы получите доступ к\u00a0чату для\u00a0общения с\u00a0единомышленниками. Обсуждайте задания, помогайте друг другу, задавайте вопросы\u00a0—\u00a0это тоже часть учебного процесса.',
    color: 'blue',
    man_webp: man2_webp,
    man_png: man2_png,
    item_webp: item2_webp,
    item_png: item2_png,
  },
  {
    id: 3,
    title: 'Ментор помогает держать курс',
    description: 'Мы предлагаем обучение с\u00a0поддержкой ментора — практикующего специалиста. Его комментарии позволят расти ещё быстрее, так\u00a0как\u00a0он постоянно сталкивается с\u00a0рабочими задачами из\u00a0разных сфер.',
    color: 'green',
    man_webp: man3_webp,
    man_png: man3_png,
    item_webp: item3_webp,
    item_png: item3_png,
  },
]

const CurseStep: React.FC = () => (
  <section>
    <ScrollComp title={'Как проходит обучение?'} content={CONTENT} />
  </section>
)

export default CurseStep;
