import React, { FC, memo } from 'react';
import cn from 'classnames';

import * as styles from './ProgramStep.module.scss';

import Text from 'src/components/ui/Text';

type Props = {
  image: string;
  className?: string;
  children: React.ReactNode;
};

const ProgramStep: FC<Props> = ({ image, children, className }) => (
  <div className={cn(styles.programStep, className)}>
    <div className={styles.programStep__imageWrap}>
      <img src={image} className={styles.programStep__image} alt={children as string} />
    </div>
    <Text element={'p'} className={styles.programStep__text}>{children}</Text>
  </div>
)

export default memo(ProgramStep);
