import * as styles from "./ButtonCollapse.module.scss";
import React, { useEffect, useState } from "react";
import cn from "classnames";

type ButtonCollapseProps = {
  isOpen: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

const ButtonCollapse:React.FC<ButtonCollapseProps> = ({ className, onClick, isOpen, ...props }) => {
  const [toggleAnimate, setToggleAnimate] = useState(false);

  useEffect(() => {
    setToggleAnimate(isOpen)
  }, [isOpen])

  return (
    <button
      onClick={(event) => {
        setToggleAnimate(!toggleAnimate)
        if (onClick) onClick(event)
      }}
      className={cn(className, styles.ButtonCollapse, toggleAnimate ? styles.ButtonCollapse__open : styles.ButtonCollapse__close)}
    >
      <span />
    </button>
  )
}

export default React.memo(ButtonCollapse);
