import * as styles from './SuccessStoriesItem.module.scss';
import cn from 'classnames';

import React from 'react';

import Text from 'src/components/ui/Text';

type SuccessStoriesItemProps = {
  name: string;
  content: string;
  img: string;
  active: boolean;
};

const SuccessStoriesItem: React.FC<SuccessStoriesItemProps> = ({name, content,img, active}) => (
  <div className={cn(styles.successStoriesItem, active && styles.successStoriesItem_active)}>
    <div className={styles.successStoriesItem__title}>
      <img src={img} alt="#"/>
      <Text element={"h3"} className={styles.successStoriesItem__name}>{name}</Text>
    </div>
    <Text className={styles.successStoriesItem__text} element={"p"}>
      {content}
    </Text>
  </div>
);

export default SuccessStoriesItem
