import React from 'react';
import * as styles from './RegistrationDone.module.scss'

import doneImg from 'src/assets/images/done.png';

import Text from "src/components/ui/Text";

type Props = {
  isShipment: Function,
}

const RegistrationDone: React.FC<Props> = ({ isShipment }) => {
  function changeRegister(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    isShipment(false);
  }
  return (
    <div className={styles.registrationDone}>
      <div className={styles.registrationDone__icon}>
        <img src={doneImg} alt=""/>
      </div>
      <div className={styles.registrationDone__title}>
        Вы успешно записаны!
      </div>
      <Text className={styles.registrationDone__subtitle}>
        <p>Получать новые знания&nbsp;-&nbsp;это&nbsp;круто! Ссылка&nbsp;на&nbsp;мастер&nbsp;-&nbsp;класс отправлена вам на почту.</p>
      </Text>
      <Text className={styles.registrationDone__mail_title}>
        <p>Указали неправильную почту?</p>
      </Text>
      <div className={styles.registrationDone__mail_button}>
        <button onClick={(e) => changeRegister(e)}>Заполнить заново</button>
      </div>
    </div>
  )
}

export default RegistrationDone
