import React, { memo, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import * as styles from './Basics.module.scss';

import PictureWebp_cone from 'src/assets/images/basic/cone.webp';
import PicturePng_cone from 'src/assets/images/basic/cone.png';

import PictureWebp_cube from 'src/assets/images/basic/cube.webp';
import PicturePng_cube from 'src/assets/images/basic/cube.png';

import PictureWebp_sphere from 'src/assets/images/basic/sphere.webp';
import PicturePng_sphere from 'src/assets/images/basic/sphere.png';

import PictureWebp_man from 'src/assets/images/basic/man.webp';
import PicturePng_man from 'src/assets/images/basic/man.png';

import PictureWebp_girl from 'src/assets/images/basic/girl.webp';
import PicturePng_girl from 'src/assets/images/basic/girl.png';

import Button from "src/components/ui/Button";

const Basics: React.FC = () => {
  const [isScroll, setIsScroll] = useState(false);

  const scrollToForm = useCallback(() => {
    document.querySelector('#record')?.scrollIntoView({
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    const osScroll = () => setIsScroll(
      window.scrollY > (document.getElementById('toWebinarHeaderButton')?.offsetTop || 0),
    );

    window.addEventListener('scroll', osScroll)

    return () => window.removeEventListener('scroll', osScroll);
  }, []);

  return (
    <section className={styles.basics} id="sectionBasics">
      <div className={styles.basics__wrapper}>
        <picture>
          <source srcSet={PictureWebp_man} type="image/webp" />
          <img className={styles.basics__man} src={PicturePng_man} alt="Man" />
        </picture>
        <picture>
          <source srcSet={PictureWebp_girl} type="image/webp" />
          <img className={styles.basics__girl} src={PicturePng_girl} alt="Girl" />
        </picture>
        <picture>
          <source srcSet={PictureWebp_cone} type="image/webp" />
          <img className={styles.basics__cone} src={PicturePng_cone} alt="Cone" />
        </picture>
        <picture>
          <source srcSet={PictureWebp_cube} type="image/webp" />
          <img className={styles.basics__cube} src={PicturePng_cube} alt="Сube" />
        </picture>
        <picture>
          <source srcSet={PictureWebp_sphere} type="image/webp" />
          <img className={styles.basics__sphere} src={PicturePng_sphere} alt="Sphere}" />
        </picture>
        <p className={styles.basics__pretitle}>С нуля до специалиста за 4 месяца. Изучите тонкости профессии и получите возможность устроиться в компанию MST.</p>
        <h1 className={styles.basics__title}>Профессия UX-дизайнер</h1>
        <p className={styles.basics__posttitle}>Получи бесплатный мастер-класс по неомрфизму, и узнай как стартануть в професии за 21 день.</p>
        <Button
          id="toWebinarHeaderButton"
          className={cn(styles.basics__button, isScroll && styles.basics__button_hide)}
          classText={styles.basics__buttonText}
          onClick={scrollToForm}
        >
          Получить сейчас
        </Button>
      </div>
    </section>
  )
}

export default memo(Basics);
