// extracted by mini-css-extract-plugin
export var doubts = "Doubts-module--doubts--35HnU";
export var doubts__cirles = "Doubts-module--doubts__cirles--11ENM";
export var cirles = "Doubts-module--cirles--2nNpF";
export var doubts__contentWrapper = "Doubts-module--doubts__contentWrapper--1Jp6e";
export var doubts__title = "Doubts-module--doubts__title--3Rt_X";
export var doubts__description = "Doubts-module--doubts__description--3NEtz";
export var doubts__inputWrapper = "Doubts-module--doubts__inputWrapper--1JEhw";
export var doubts__input = "Doubts-module--doubts__input--1PmUo";
export var doubts__button = "Doubts-module--doubts__button--13G6w";
export var doubts__agreement = "Doubts-module--doubts__agreement--2AD2x";
export var doubts__icon = "Doubts-module--doubts__icon--316xV";
export var icons = "Doubts-module--icons--3xZf3";
export var doubts__icon_small = "Doubts-module--doubts__icon_small--1Vdov";
export var doubts__icon_first = "Doubts-module--doubts__icon_first--17Nuf";
export var doubts__icon_second = "Doubts-module--doubts__icon_second--4pD8L";
export var doubts__icon_third = "Doubts-module--doubts__icon_third--Vl57y";
export var doubts__icon_fourth = "Doubts-module--doubts__icon_fourth--3ga4N";
export var doubts__icon_fifth = "Doubts-module--doubts__icon_fifth--11jC2";
export var doubts__icon_sixth = "Doubts-module--doubts__icon_sixth--gHVAJ";
export var doubts__icon_seventh = "Doubts-module--doubts__icon_seventh--18sPp";
export var doubts__successSend = "Doubts-module--doubts__successSend--2gYQo";