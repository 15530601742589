// extracted by mini-css-extract-plugin
export var sectionResume = "SectionResume-module--sectionResume--ixjWI";
export var sectionResume__container = "SectionResume-module--sectionResume__container--1d5w9";
export var sectionResume__item = "SectionResume-module--sectionResume__item--11KO0";
export var sectionResume__title = "SectionResume-module--sectionResume__title--7PCfH";
export var sectionResume__learner = "SectionResume-module--sectionResume__learner--2QZzj";
export var sectionResume__learnerData = "SectionResume-module--sectionResume__learnerData--1ugPU";
export var sectionResume__learnerAvatar = "SectionResume-module--sectionResume__learnerAvatar--2y29t";
export var sectionResume__learnerTitle = "SectionResume-module--sectionResume__learnerTitle--yIeia";
export var sectionResume__learnerDescription = "SectionResume-module--sectionResume__learnerDescription--2XE1d";
export var sectionResume__skills = "SectionResume-module--sectionResume__skills--qVGFt";
export var sectionResume__skillsList = "SectionResume-module--sectionResume__skillsList--1YaO1";
export var sectionResume__skillsListItem = "SectionResume-module--sectionResume__skillsListItem--1jivb";
export var sectionResume__button = "SectionResume-module--sectionResume__button--vG-ss";
export var sectionResume__textButton = "SectionResume-module--sectionResume__textButton--3XYEc";
export var sectionResume__skillBlock = "SectionResume-module--sectionResume__skillBlock--2REW5";
export var sectionResume__hardSkills = "SectionResume-module--sectionResume__hardSkills--1mnsL";
export var sectionResume__hardSkillsTitle = "SectionResume-module--sectionResume__hardSkillsTitle--1Si8J";
export var sectionResume__hardSkillsItems = "SectionResume-module--sectionResume__hardSkillsItems--2MTwU";
export var sectionResume__resources = "SectionResume-module--sectionResume__resources--W2ZYD";
export var sectionResume__resourcesTitle = "SectionResume-module--sectionResume__resourcesTitle--2aqZs";
export var sectionResume__resourcesItems = "SectionResume-module--sectionResume__resourcesItems--h8AkF";
export var sectionResume__resourcesItem = "SectionResume-module--sectionResume__resourcesItem--2RP_H";
export var sectionResume__resourcesItem_small = "SectionResume-module--sectionResume__resourcesItem_small--O_bW5";
export var sectionResume__resourcesText = "SectionResume-module--sectionResume__resourcesText--1lTCb";