export default [
  {
    text: "У меня нет опыта в UX-дизайне. Подойдёт ли мне программа?",
    subText: "Программа построена по принципу «от простого к сложному».\n" +
      "\n" +
      "При регистрации на вебинар, вы получите доступ к первым двум модулям бесплатно, познакомитесь с профессией и узнаете, какие навыки нужны UX-дизайнеру и интересна ли вам эта сфера. \n" +
      "\n" +
      "А также, если вы готовы расти семимильными шагами, то обязательно записывайтесь на персональное менторство.",
  },
  {
    text: "Сколько длится курс и как проходит обучение?",
    subText: "Материал курса рассчитан на 4 месяца. Однако вы можете проходить его в своём темпе. Но мы рекомендуем укладываться в тайминг, чтобы ваше обучение было наиболее продуктивным.",
  },
  {
    text: "Сколько времени в неделю мне нужно будет уделять учёбе?",
    subText: "Всё зависит от вашей загруженности и темпа обучения. На изучение теории и выполнение заданий в среднем уходит до 6-ти часов в неделю.",
  },
  {
    text: "Что делать, если я не успеваю?",
    subText: "После того, как курс прошёл, вы по-прежнему остаётесь в чате вашего потока и можете задать вопрос преподавателю и одногруппникам, выполнить и отправить задание.",
  },
  {
    text: "Вы помогаете с трудоустройством?",
    subText: "Мы не можем обещать гарантированную работу всем, но в процеессе обучения определим тех, кому MST (агентство-интегратор для технологических компаний) сможет предложить оплачиваемую стажировку.\n" +
      "\n" +
      "Во время стажировки вы получите возможность поработать над реальными задачами агентства, получите практический опыт и по итогу — обратную связь от наставника для дальнейшего развития. А тем, кто успешно справится со всеми задачами, агентство сделает предложение об оформлении в штат.",
  },
  {
    text: "Почему ваш подход действительно работает?",
    subText: "Мы предлагаем обучение с поддержкой ментора — практикующего специалиста, работающего в штате агентства. Много времени в процессе обучения уделяем практике, чтобы вы могли оттачивать свои навыки.",
  },
  {
    text: "Есть ли у вас рассрочка на платную часть курса?",
    subText: "Да, GH Campus предоставляет такую возможность, без кредитов в бакнах\n" + "и процентов по ним.",
  },
].map((el, i) => ({ ...el, id: i + 1 }));
