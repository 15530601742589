import * as styles from "./SectionQuestions.module.scss";

import React from "react";
import Text from "src/components/ui/Text";

import QuestionItem from "src/components/SectionQuestions/QuestionItem";
import data from "src/components/SectionQuestions/data";
import Button from "src/components/ui/Button/Button";
import tg_png from "src/assets/images/tg_white.png";
import tg_webp from "src/assets/images/tg_white.webp";

const SectionQuestions: React.FC = () => {
  return (
    <section className={styles.sectionQuestions}>
      <Text className={styles.sectionQuestions__title} element={"h2"} view={"h2"}>
        Часто задаваемые вопросы
      </Text>
      <ul className={styles.sectionQuestions__listQuestions}>
        {data.map((el) => (
          <QuestionItem key={el.id} text={el.text} subText={el.subText} />
        ))}
      </ul>
      <div className={styles.sectionQuestions__tgLinkWrapper}>
        <p className={styles.sectionQuestions__linkDescription}>
          <div>Остались вопросы? Напишите нам на почту</div>
          <div>info@growheads.ru или напряму в наш телеграм-канал.</div>
        </p>
        <Button
          element='a'
          target = '_blank'
          href='https://t.me/pixel_map/4'
        >
          <span className={styles.sectionQuestions__buttonContent}>
            <picture className={styles.sectionQuestions__imageTg}>
              <source srcSet={tg_png} type="image/png" />
              <source srcSet={tg_webp} type="image/webp" />
              <img className={styles.basics__cone} src={tg_png} alt="tg_png" />
            </picture>
            Задать вопрос
          </span>
        </Button>
      </div>
    </section>
  );
};

export default React.memo(SectionQuestions);
