import React, { useCallback } from "react";
import * as styles from './SectionStartLearning.module.scss';

import SkateMan from '../../assets/SectionStartLearning/skateMan.png';

const SectionStartLearning: React.FC = () => {
  const scrollToForm = useCallback(() => {
    document.querySelector('#doubtsCirles')?.scrollIntoView({
      behavior: 'smooth'
    });
  }, []);

  return (
    <section onClick={scrollToForm} className={styles.SectionStartLearning}>
      <div className={styles.SectionStartLearning__container}>
        <div className={styles.SectionStartLearning__wrapper}>
          <img className={styles.SectionStartLearning__img} src={SkateMan} alt="Skate Man" />
          <h3 className={styles.SectionStartLearning__title}>Начать учиться!</h3>
          <p className={styles.SectionStartLearning__subTitle}>Оставьте заявку на получение доступа к первым двум модулям — бесплатно</p>
        </div>
      </div>
    </section>
  )
}

export default SectionStartLearning;
