// extracted by mini-css-extract-plugin
export var programCard = "ProgramCard-module--programCard--20siF";
export var programCard_opened = "ProgramCard-module--programCard_opened--3fNjd";
export var programCard__description = "ProgramCard-module--programCard__description--PN4tR";
export var programCard_fewTags = "ProgramCard-module--programCard_fewTags--1T0T4";
export var programCard__tags = "ProgramCard-module--programCard__tags--1b2Wc";
export var programCard__header = "ProgramCard-module--programCard__header--23FBC";
export var programCard__textWrap = "ProgramCard-module--programCard__textWrap--2z_TR";
export var programCard__title = "ProgramCard-module--programCard__title--1K1Px";
export var programCard__tagsItem = "ProgramCard-module--programCard__tagsItem--3rLOM";
export var programCard__btn = "ProgramCard-module--programCard__btn--3ibpd";
export var programCard__steps = "ProgramCard-module--programCard__steps--1uW7Q";
export var programCard__stepsItem = "ProgramCard-module--programCard__stepsItem--3UaFE";