import * as React from 'react';
import Layout from 'src/components/Layout';
import Basics from "src/components/Basics";
import SectionWebinar from "src/components/SectionWebinar";
import PlusesCourse from 'src/components/PlusesCourse';
import CurseStep from 'src/components/CurseStep';
import Resume from "src/components/SectionResume";
import SectionQuestions from "src/components/SectionQuestions";
import Program from "src/components/Program";
import Doubts from 'src/components/Doubts';
import SuccessStories from "src/components/SuccessStories";
import SectionStartLearning from "src/components/SectionStartLearning";
import Header from "src/components/Header";
import TargetAudience from "src/components/TargetAudience";
import Footer from 'src/components/Footer';

const UXDesignPage = () => (
  <Layout>
    <Header />
    <Basics />
    <PlusesCourse />
    <Program />
    <TargetAudience />
    <div style={{ backgroundColor: 'white', zIndex: 5, position: 'relative' }}>
      <SectionWebinar />
      <CurseStep />
      <div style={{ backgroundColor: 'white', zIndex: 5, position: 'relative' }}>
        <Resume />
        <Doubts />
        <SectionQuestions />
        <SuccessStories />
        <SectionStartLearning />
        <Footer />
      </div>
    </div>
  </Layout>
);

export default UXDesignPage;
