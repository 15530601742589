import * as styles from './FormRegistration.module.scss';
import React, {FormEvent, useCallback, useEffect, useRef, useState} from 'react';
import Input from 'src/components/ui/Input';
// @ts-ignore
import usePhoneFormatter from 'src/scripts/usePhoneFormatter';
import Button from "src/components/ui/Button";
import cn from "classnames";
import Popup from "src/components/ui/Popup";
import PopupPersonalData from "src/components/PopupPersonalData";
import mail from "src/api/mail";

// Props for change element for mini card
type toggleSectionProps = {
  toggleProps?: boolean | undefined,
  isShipment: Function,
}

const PRICE = 1;

const form = {
  terminalkey: '1622616579892',
  frame: 'true',
  language: 'ru',
  amount: PRICE.toString(),
  order: new Date().getTime(),
  description: 'Информационные услуги',
};

const FormRegistration: React.FC<toggleSectionProps> = ({ toggleProps, isShipment }) => {
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [telephone, setTelephone] = useState('');
  const { onPhoneKeyDown, onPhoneInput, onPhonePaste } = usePhoneFormatter();
  const [isMounted, setIsMounted] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const Tinkoff = useRef<any>(null);

  const receipt = {
    Email: email,
    Taxation: 'usn_income',
    Items: [
      {
        Name: form.description,
        Price: PRICE * 100,
        Quantity: 1,
        Amount: PRICE * 100,
        PaymentMethod: 'full_payment',
        PaymentObject: 'service',
        Tax: 'none',
      },
    ],
  }

  const onClosePolicyPopup = useCallback(() => setIsOpenPolicy(false), []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      import('react-tinkoff-pay').then((d) => {
        Tinkoff.current = d.default;
        setIsMounted(true);
      });
    }
  }, []);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsPayment(false);
    setIsRequest(true);
    mail({
      email, name, type: 'webinar',
    }).then(() => isShipment(true)).finally(() => setIsRequest(false));
  };

  return (
    <div className={cn(styles.formRegistration__wrapper, toggleProps && styles.formRegistration__wrapper_active)}>
      <h2 className={styles.formRegistration__title}>{toggleProps ? (
        <>
          Оставь почту для&nbsp;получения <span className={styles.formRegistration__highlight}>мастер-класса</span>
        </>
      ) : 'Заполните форму, чтобы оформить заказ'}</h2>
      <div className={styles.formRegistration__info}>
        <span className={cn(styles.formRegistration__infoValue, styles.formRegistration__infoValue_black)}>Неоморфизм</span>
        <span className={cn(styles.formRegistration__infoValue, styles.formRegistration__infoValue_green)}>Бесплатно</span>
      </div>
      <form className={styles.formRegistration__form} onSubmit={onSubmit}>
        <label className={styles.formRegistration__label}>
          <span className={styles.formRegistration__personalInformation}>Введите ваш Email адрес</span>
          <Input value={email} onChange={setEmail} placeholder="E-mail" name="email" />
        </label>
        <label className={styles.formRegistration__label}>
          <span className={styles.formRegistration__personalInformation}>Как вас зовут?</span>
          <Input value={name} onChange={setName} placeholder="Ваше имя" name="name" />
        </label>
        {!toggleProps &&
        <label className={styles.formRegistration__label}>
          <span className={styles.formRegistration__personalInformation}>Телефон</span>
          <Input
            value={telephone}
            onChange={setTelephone}
            placeholder="Номер телефона"
            onInput={onPhoneInput}
            onKeyDown={onPhoneKeyDown}
            onPaste={onPhonePaste}
          />
        </label>
        }
        <div className={cn(toggleProps && styles.formRegistration__columnFooter, styles.formRegistration__footer)}>
          <p
            className={styles.formRegistration__personalText}
          >
            Нажимая на кнопку, я соглашаюсь на
            {' '}
            <a
              className={styles.formRegistration__link}
              href="#popup"
              onClick={(e) => {
                e.preventDefault();
                setIsOpenPolicy(true);
              }}
            >
              обработку персональных данных
            </a>
          </p>
          <Button
            className={cn(toggleProps && styles.formRegistration__columnBtn, styles.formRegistration__btn)}
            disabled={!/(.+)@(.+)/.test(email) || !name}
            isLoading={isRequest}
          >
            Записаться
          </Button>
        </div>
      </form>

      {isMounted && isPayment && <Tinkoff.current.Pay form={{ ...form, name, email, phone: telephone, receipt: JSON.stringify(receipt) }} onClose={() => setIsPayment(false)} />}

      <Popup onClose={onClosePolicyPopup} open={isOpenPolicy}>
        <PopupPersonalData type={0} />
      </Popup>
    </div>
  )
}

export default React.memo(FormRegistration);
