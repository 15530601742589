import * as styles from './PlusesCourse.module.scss';

import React from 'react';

import Text from 'src/components/ui/Text';
import PlusesCourseCard from './components/PlusesCourseCard';

import pic0_webp from 'src/assets/images/with-pencil.webp'
import pic1_webp from 'src/assets/images/with-rocket.webp'

import pic0_png from 'src/assets/images/with-pencil.png'
import pic1_png from 'src/assets/images/with-rocket.png'

const CONTENT = [
  {
    id: 0,
    title: 'Mentoring',
    content: 'Трамплин в обучении. Передача знаний, навыков и стратегий достижения результата от практикующих экспертов.\n',
    src_webp: pic0_webp,
    src_png: pic0_png,
    size: 'middle',
    color: 'blue',
  },
  {
    id: 1,
    title: 'Mindset',
    content: 'Развитие необходимого  набора убеждений, привычек мышления.',
    src_webp: pic1_webp,
    src_png: pic1_png,
    size: 'small',
    color: 'orange',
  },
]

const PlusesCourse: React.FC = () => (
    <div className={styles.plusesCourse}>
        <Text element="span" align="Center" className={styles.plusesCourse__title}>
          Почему одни строят карьеру за год, другие — нет?
        </Text>
        <div className={styles.plusesCourse__blocks}>
        {CONTENT.map((item) =>
          <PlusesCourseCard key={item.id} item={item} className={styles.plusesCourse__card} />
        )}
        </div>
    </div>
);

export default React.memo(PlusesCourse);
