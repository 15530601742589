import * as styles from './SuccessStories.module.scss';
import cn from 'classnames';

import React, { useState } from "react";

import face_1 from 'src/assets/SuccessStories/face_1.jpg'
import face_2 from 'src/assets/SuccessStories/face_2.jpg'
import face_3 from 'src/assets/SuccessStories/face_3.jpg'
import face_4 from 'src/assets/SuccessStories/face_4.jpg'
import face_5 from 'src/assets/SuccessStories/face_5.jpg'
import face_6 from 'src/assets/SuccessStories/face_6.jpg'

import SuccessStoriesItem from "src/components/SuccessStories/SuccessStoriesItem/SuccessStoriesItem";
import Rocket from "src/assets/images/rocket.png";
import Rocket_webp from "src/assets/images/rocket.webp";
import Bell from "src/assets/images/bell.png";
import Bell_webp from "src/assets/images/bell.webp";

const Stories = [
  {
    id: 0,
    name: "Владимир Григорьев",
    content: "Очень  понравилась методика преподавания. Максим  и кураторы объяснили доступным языком непонятные  моменты. Правда некоторые слова (сленг) были немного не понятны, но помог гугл и память =) Сейчас у меня в голове есть четкая понятная структура что изучать и как двигаться в диджитал дизайне! Спасибо команде MST",
    img: face_1
  },
  {
    id: 1,
    name: "Евгений Летов",
    content: "Просто и легко доносили информацию, приятная атмосфера, лучшие менторы, теперь я на один шаг ближе к карьере веб дизайнера :)",
    img: face_2
  },
  {
    id: 2,
    name: "Кирилл Гурьянов",
    content: "Спасибо большое за интенсив) жаль что так мало, но за эти дни было много полезной и качественно поданной информации) все было очень круто, все как и я понял что есть всегда чему учиться и к чему стремиться, еще раз спасибо что помогаете развиваться, и делать мир ярче",
    img: face_3
  },
  {
    id: 3,
    name: "Марина Губкина",
    content: "...Курсы того стоили. Я даже представить не могла, что смогу настолько погрузиться в этот процесс. Было поначалу тяжеловато для меня, как для новичка. Я узнала как выглядят красные от ноутбука глаза когда сидишь ночью за ним. А потом утром с нетерпением ждешь, когда на разборе дойдет твоя очередь, и каждое замечание берешь в работе. Спасибо огромное Максим! Ты вложил в наши головы тонны ценной информации...",
    img: face_4
  },
  {
    id: 4,
    name: "Анастасия Батюшкина",
    content: "Понравилось, что лекции проводились в легкой и доступной форме. Также предоставлялось куча ссылок на полезную литературу и статьи. Сам интенсив дал хорошую платформу для дальнейшего самостоятельного развития. Еще хочется сказать спасибо менторам за проявленное терпение и ценные советы, и, в целом, спасибо всей команде за возможность посещения такого мероприятия)",
    img: face_5
  },
  {
    id: 5,
    name: "Екатерина Скворцова",
    content: "...Менторы у нас мега крутые, нам повезло с ними! Особая благодарность моему ментору Анне! Теперь я знаю, к чему и к комму стремиться! Теперь знаю главное расширять свой кругозор и копировать, копировать, и копировать, а потом сама стану оригиналом. MST Успехов и процветания вам!",
    img: face_6
  }
];

const SuccessStories: React.FC = () => {
  const [history, setHistory] = useState(0);
  return(
    <div className={styles.successStories}>
      <h1 className={styles.successStories__title}>Отзывы наших учеников</h1>
      <div className={styles.successStories__visibItem}>
        <picture className={styles.successStories__bell}>
          <source srcSet={Bell_webp} type="image/webp" />
          <img src={Bell} alt="Bell" />
        </picture>
        <picture className={styles.successStories__rocket}>
          <source srcSet={Rocket_webp} type="image/webp" />
          <img src={Rocket} alt="Rocket" />
        </picture>
        { history !== 0 && <div className={cn(styles.successStories__arrow, styles.successStories__arrowPrev)} onClick={() => setHistory(history - 1)} /> }
        <div className={styles.successStories__items} style={{transform: `translate(calc(${history} * -16.666%), -50%)`}}>
          {Stories.map((el) => (
            <SuccessStoriesItem key={el.id} name={el.name} content={el.content} img={el.img} active={el.id === history}/>
          ))}
        </div>
        { history !== 5 && <div className={cn(styles.successStories__arrow, styles.successStories__arrowNext)} onClick={() => setHistory(history + 1)} /> }
      </div>
    </div>
  );
}

export default SuccessStories
