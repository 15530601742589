// extracted by mini-css-extract-plugin
export var scrollComp = "ScrollComp-module--scrollComp--N71cK";
export var scrollComp__titleWrapper = "ScrollComp-module--scrollComp__titleWrapper--2dhwO";
export var scrollComp__titleWrapper_orange = "ScrollComp-module--scrollComp__titleWrapper_orange--G4UUN";
export var scrollComp__titleWrapper_lightviolet = "ScrollComp-module--scrollComp__titleWrapper_lightviolet--omwHL";
export var scrollComp__title = "ScrollComp-module--scrollComp__title--3jHjq";
export var scrollComp__title_hide = "ScrollComp-module--scrollComp__title_hide--2yT4l";
export var scrollComp__cardContainer = "ScrollComp-module--scrollComp__cardContainer--3fz9K";
export var scrollComp__cardTitle = "ScrollComp-module--scrollComp__cardTitle--7g2kG";
export var scrollComp__card = "ScrollComp-module--scrollComp__card--Ijvcu";
export var scrollComp__color_orange = "ScrollComp-module--scrollComp__color_orange--CcBSe";
export var scrollComp__color_blue = "ScrollComp-module--scrollComp__color_blue--3WsaJ";
export var scrollComp__color_lightviolet = "ScrollComp-module--scrollComp__color_lightviolet--1DPZ8";
export var scrollComp__color_lightred = "ScrollComp-module--scrollComp__color_lightred--1FT7i";
export var scrollComp__color_darkblue = "ScrollComp-module--scrollComp__color_darkblue--2xQFB";
export var scrollComp__color_violet = "ScrollComp-module--scrollComp__color_violet--92yw9";
export var scrollComp__color_green = "ScrollComp-module--scrollComp__color_green--3lWJv";
export var scrollComp__cardContentWrapper = "ScrollComp-module--scrollComp__cardContentWrapper--Yw3oA";
export var scrollComp__cardContent = "ScrollComp-module--scrollComp__cardContent--Dtboo";
export var scrollComp__cardDescription = "ScrollComp-module--scrollComp__cardDescription--3ddIQ";
export var scrollComp__pictureMan = "ScrollComp-module--scrollComp__pictureMan--1__mx";
export var scrollComp__pictureItem = "ScrollComp-module--scrollComp__pictureItem--QqEJ_";