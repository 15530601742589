import card_1_img_1 from 'src/assets/images/program/card_1/img_1.png';
import card_1_img_2 from 'src/assets/images/program/card_1/img_2.png';
import card_1_img_3 from 'src/assets/images/program/card_1/img_3.png';
import card_1_img_4 from 'src/assets/images/program/card_1/img_4.png';
import card_1_img_5 from 'src/assets/images/program/card_1/img_5.png';

import card_2_img_1 from 'src/assets/images/program/card_2/img_1.png';
import card_2_img_2 from 'src/assets/images/program/card_2/img_2.png';
import card_2_img_3 from 'src/assets/images/program/card_2/img_3.png';
import card_2_img_4 from 'src/assets/images/program/card_2/img_4.png';

import card_3_img_1 from 'src/assets/images/program/card_3/img_1.png';
import card_3_img_2 from 'src/assets/images/program/card_3/img_2.png';
import card_3_img_3 from 'src/assets/images/program/card_3/img_3.png';
import card_3_img_4 from 'src/assets/images/program/card_3/img_4.png';
import card_3_img_5 from 'src/assets/images/program/card_3/img_5.png';
import card_3_img_6 from 'src/assets/images/program/card_3/img_6.png';

import card_4_img_1 from 'src/assets/images/program/card_4/img_1.png';
import card_4_img_2 from 'src/assets/images/program/card_4/img_2.png';
import card_4_img_3 from 'src/assets/images/program/card_4/img_3.png';
import card_4_img_4 from 'src/assets/images/program/card_4/img_4.png';
import card_4_img_5 from 'src/assets/images/program/card_4/img_5.png';
import card_4_img_6 from 'src/assets/images/program/card_4/img_6.png';

import simple_card_img_1 from 'src/assets/images/program/SimpleCards/img_1.png';
import simple_card_img_2 from 'src/assets/images/program/SimpleCards/img_2.png';
import simple_card_img_3 from 'src/assets/images/program/SimpleCards/img_3.png';
import simple_card_img_4 from 'src/assets/images/program/SimpleCards/img_4.png';
import simple_card_img_5 from 'src/assets/images/program/SimpleCards/img_5.png';
import simple_card_img_6 from 'src/assets/images/program/SimpleCards/img_6.png';

import simple_card_img_webp_1 from 'src/assets/images/program/SimpleCards/imgwebp_1.webp';
import simple_card_img_webp_2 from 'src/assets/images/program/SimpleCards/imgwebp_2.webp';
import simple_card_img_webp_3 from 'src/assets/images/program/SimpleCards/imgwebp_3.webp';
import simple_card_img_webp_4 from 'src/assets/images/program/SimpleCards/imgwebp_4.webp';
import simple_card_img_webp_5 from 'src/assets/images/program/SimpleCards/imgwebp_5.webp';
import simple_card_img_webp_6 from 'src/assets/images/program/SimpleCards/imgwebp_6.webp';

import { BadgeVariant } from '../ui/Badge/Badge';

const programData = [
  {
    id: 1,
    title: '1. Введение в профессию',
    description: 'Поймём разницу между UX и UI дизайном. Проникнемся философией digital-дизайна',
    description2: 'Поймете разницу между UX и UI дизайном. Проникнитесь философией digital-дизайна. Познакомитесь с основными этапами разработки интерфейса и ролью дизайнера на каждом из них. Узнаете, как развиваться дизайнеру и не допускать ошибок, которые совершают новички. ',
    tags: [
      {
        id: 'tag1',
        text: 'Теория',
        variant: BadgeVariant.GREY,
      },
      {
        id: 'tag2',
        text: 'Практика',
        variant: BadgeVariant.YELLOW,
      },
      {
        id: 'tag3',
        text: 'Контроль знаний',
        variant: BadgeVariant.PINK,
      },
    ],
    steps: [
      {
        id: 'card1',
        image: card_1_img_1,
        text: '1. UX/UI-дизайн',
      },
      {
        id: 'card2',
        image: card_1_img_2,
        text: '2. Философия digital-дизайна',
      },
      {
        id: 'card3',
        image: card_1_img_3,
        text: '3. Основные этапы разработки интерфейса',
      },
      {
        id: 'card4',
        image: card_1_img_4,
        text: '4. Развитие дизайна внутри себя',
      },
      {
        id: 'card5',
        image: card_1_img_5,
        text: '5. Основные ошибки новичков',
      },
    ],
  },
  {
    id: 2,
    title: '2. Исследование и анализ',
    description: 'Узнаете, что такое брифинг, как к нему готовиться, как определить состав...',
    description2: 'Узнаете, что такое брифинг, как к нему готовиться, как определить состав участников и как его проводить. Познакомитесь с основными типами вопросов для брифа, когда и какие задавать. Научитесь проводить анализ конкурентов по разным категориям. Узнаете, как грамотно применять в своей работе референсы, как и где их искать под свои задачи.',
    tags: [
      {
        id: 'tag1',
        text: 'Теория',
        variant: BadgeVariant.GREY,
      },
      {
        id: 'tag2',
        text: 'Практика',
        variant: BadgeVariant.YELLOW,
      },
      {
        id: 'tag3',
        text: 'Контроль знаний',
        variant: BadgeVariant.PINK,
      },
      {
        id: 'tag4',
        text: 'Брифинг',
        variant: BadgeVariant.PURPLE,
      },
      {
        id: 'tag5',
        text: 'Работа с референсами',
        variant: BadgeVariant.GREEN,
      },
    ],
    steps: [
      {
        id: 'card1',
        image: card_2_img_1,
        text: '1. Брифинг',
      },
      {
        id: 'card2',
        image: card_2_img_2,
        text: '2. Кради как художник',
      },
      {
        id: 'card3',
        image: card_2_img_3,
        text: '3. Анализ конкурентов',
      },
      {
        id: 'card4',
        image: card_2_img_4,
        text: '4. Поиск референсов',
      },
    ],
  },
  {
    id: 3,
    title: '3. Проектирование',
    description: 'Познакомитесь с основами проектирования. Освоите популярные инструме...',
    description2: 'Познакомитесь с основами проектирования. Освоите популярные инструменты, с их помощью составите портрет пользователя, определите его боли и потребности, основные задачи в проектируемом интерфейсе. Определите основные сценарии, смоделируете путь пользователя и продумаете, как его улучшить. Научитесь отбирать идеи и проводить пользовательские исследования (опрос, юзабилити-тестирование).',
    tags: [
      {
        id: 'tag1',
        text: 'Теория',
        variant: BadgeVariant.GREY,
      },
      {
        id: 'tag2',
        text: 'Практика',
        variant: BadgeVariant.YELLOW,
      },
      {
        id: 'tag3',
        text: 'Инструменты',
        variant: BadgeVariant.PURPLE,
      },
      {
        id: 'tag4',
        text: 'Контроль знаний',
        variant: BadgeVariant.PINK,
      },
      {
        id: 'tag5',
        text: 'Отбор идей',
        variant: BadgeVariant.GREEN,
      },
    ],
    steps: [
      {
        id: 'card1',
        image: card_3_img_1,
        text: '1. Основы проектирования',
      },
      {
        id: 'card2',
        image: card_3_img_2,
        text: '2. Персоны и карты эмпатии',
      },
      {
        id: 'card3',
        image: card_3_img_3,
        text: '3. Карты пользователей',
      },
      {
        id: 'card4',
        image: card_3_img_4,
        text: '4. Карты разрывов',
      },
      {
        id: 'card5',
        image: card_3_img_5,
        text: '5. Отбор идей и переход к прототипированию',
      },
      {
        id: 'card6',
        image: card_3_img_6,
        text: '6. Дополнительные материалы. Опрос. Юзабилити-тесты',
      },
    ],
  },
  {
    id: 4,
    title: '4. Прототипирование',
    description: 'Узнаете, что такое прототипирование и зачем оно нужно. Рассмотрите разные...',
    description2: 'Узнаете, что такое прототипирование и зачем оно нужно. Рассмотрите разные виды прототипов. Создадите свой первый прототип. Узнаете, какие бывают микровзаимодействия, начнете формировать свою коллекцию. Познакомитесь с UI-паттернами и узнаете, как и где находить паттерны под свои задачи.',
    tags: [
      {
        id: 'tag1',
        text: 'Теория',
        variant: BadgeVariant.GREY,
      },
      {
        id: 'tag2',
        text: 'Практика',
        variant: BadgeVariant.YELLOW,
      },
      {
        id: 'tag3',
        text: 'Контроль знаний',
        variant: BadgeVariant.PINK,
      },
      {
        id: 'tag4',
        text: 'UI-паттерны',
        variant: BadgeVariant.PURPLE,
      },
      {
        id: 'tag5',
        text: 'Микровзаимодействия',
        variant: BadgeVariant.GREEN,
      },
    ],
    steps: [
      {
        id: 'card1',
        image: card_4_img_1,
        text: '1. Прототипирование',
      },
      {
        id: 'card2',
        image: card_4_img_2,
        text: '2. Принципы прототипирования',
      },
      {
        id: 'card3',
        image: card_4_img_3,
        text: '3. Виды прототипов',
      },
      {
        id: 'card4',
        image: card_4_img_4,
        text: '4. Микровзаимодействия в интерфейсе',
      },
      {
        id: 'card5',
        image: card_4_img_5,
        text: '5. UI-паттерны',
      },
      {
        id: 'card6',
        image: card_4_img_6,
        text: '6. Общие правила в работе над прототипом',
      },
    ],
  },
];

export const simpleProgramData = [
  {
    id: 1,
    description: 'Проектировать интерфейсы на основе поведения пользователей',
    image: simple_card_img_1,
    image_webp: simple_card_img_webp_1,
  },
  {
    id: 2,
    description: 'Строить пути пользователей с помощью CJM',
    image: simple_card_img_2,
    image_webp: simple_card_img_webp_2,
  },
  {
    id: 3,
    description: 'Составлять карту разрыва (Gap Map) для выявления проблем при взаимодействии с продуктом',
    image: simple_card_img_3,
    image_webp: simple_card_img_webp_3,
  },
  {
    id: 4,
    description: 'Использовать методику How Might We для первичной генерации идей',
    image: simple_card_img_4,
    image_webp: simple_card_img_webp_4,
  },
  {
    id: 5,
    description: 'Отбирать идеи с помощью ранжирования, диаграммы Венна, Product Evolution Canvas',
    image: simple_card_img_5,
    image_webp: simple_card_img_webp_5,
  },
  {
    id: 6,
    description: 'Проводить пользовательские исследования и юзабилити-тестирования',
    image: simple_card_img_6,
    image_webp: simple_card_img_webp_6,
  },

]

export default programData;
