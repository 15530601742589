// extracted by mini-css-extract-plugin
export var basics = "Basics-module--basics--2ohA-";
export var basics__pretitle = "Basics-module--basics__pretitle--1nLyN";
export var basics__posttitle = "Basics-module--basics__posttitle--ouHoo";
export var basics__title = "Basics-module--basics__title--yX64l";
export var basics__info = "Basics-module--basics__info--PVnR6";
export var basics__infoText = "Basics-module--basics__infoText--1no0Z";
export var basics__infoValue = "Basics-module--basics__infoValue--2VRt1";
export var basics__infoValue_orange = "Basics-module--basics__infoValue_orange--2gtdR";
export var basics__infoValue_green = "Basics-module--basics__infoValue_green--NyyaM";
export var basics__wrapper = "Basics-module--basics__wrapper--15JQU";
export var basics__button = "Basics-module--basics__button--20Jgi";
export var basics__button_hide = "Basics-module--basics__button_hide--2EwVW";
export var basics__buttonText = "Basics-module--basics__buttonText--35XTz";
export var basics__man = "Basics-module--basics__man--2NKyr";
export var basics__girl = "Basics-module--basics__girl--1L8fk";
export var basics__cone = "Basics-module--basics__cone--Tj2dE";
export var basics__cube = "Basics-module--basics__cube--2owBR";
export var basics__sphere = "Basics-module--basics__sphere--1oH5k";