// extracted by mini-css-extract-plugin
export var sectionWebinar = "SectionWebinar-module--sectionWebinar--26BuS";
export var sectionWebinar__container = "SectionWebinar-module--sectionWebinar__container--1CZ_7";
export var sectionWebinar__cell = "SectionWebinar-module--sectionWebinar__cell--3DDNh";
export var sectionWebinar__cell_first = "SectionWebinar-module--sectionWebinar__cell_first--2oeJa";
export var sectionWebinar__cell_second = "SectionWebinar-module--sectionWebinar__cell_second--1ZLGK";
export var sectionWebinar__cell_third = "SectionWebinar-module--sectionWebinar__cell_third--rSMoG";
export var sectionWebinar__cell_left = "SectionWebinar-module--sectionWebinar__cell_left--3phzo";
export var sectionWebinar__banner = "SectionWebinar-module--sectionWebinar__banner--2DQq6";
export var sectionWebinar__iframe = "SectionWebinar-module--sectionWebinar__iframe--mRrnq";
export var sectionWebinar__img = "SectionWebinar-module--sectionWebinar__img--XmrwJ";
export var sectionWebinar__img_hidden = "SectionWebinar-module--sectionWebinar__img_hidden--2GvT7";
export var sectionWebinar__labelList = "SectionWebinar-module--sectionWebinar__labelList--1lxy0";
export var sectionWebinar__label = "SectionWebinar-module--sectionWebinar__label--_U1CV";
export var sectionWebinar__label_first = "SectionWebinar-module--sectionWebinar__label_first--3PVKD";
export var sectionWebinar__label_second = "SectionWebinar-module--sectionWebinar__label_second--1HoMU";
export var sectionWebinar__label_third = "SectionWebinar-module--sectionWebinar__label_third--3OnWR";
export var sectionWebinar__title = "SectionWebinar-module--sectionWebinar__title--1oPec";
export var sectionWebinar__teacher = "SectionWebinar-module--sectionWebinar__teacher--1J0_K";
export var sectionWebinar__avatar = "SectionWebinar-module--sectionWebinar__avatar--2CLWl";
export var sectionWebinar__name = "SectionWebinar-module--sectionWebinar__name--2La-f";
export var sectionWebinar__info = "SectionWebinar-module--sectionWebinar__info--18rml";