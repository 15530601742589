import React, { FC, useState } from "react";
import cn from 'classnames'

import Text from 'src/components/ui/Text';

import ProgramCard from './components/ProgramCard';
import SimpleProgramCard from './components/SimpleProgramCard';
import programData, { simpleProgramData } from './ProgramData';

import * as styles from './Program.module.scss';

const Program: FC = () => {
  const [simply, setSimply] = useState(true);
  return (
    <section className={styles.program}>
      <div className={styles.program__container}>
        <Text
          className={styles.program__title}
          element={'h2'}
          align={'Center'}
          weight={'500'}
        >
          Учим необходимому
        </Text>
        <div className={styles.program__switchContainer}>
          <Text className={cn(styles.program__switch, simply && styles.program__switch_active)} onClick={() => setSimply(true)}>
            По-простому
          </Text>
          <Text className={cn(styles.program__switch, !simply && styles.program__switch_active)} onClick={() => setSimply(false)}>
            Детально
          </Text>
        </div>
        {simply && <div className={styles.program__simpleCardConteiner}>
          {simpleProgramData.map(({ id, description, image, image_webp }) => (
            <SimpleProgramCard
              key={id}
              className={styles.program__textContent}
              id={id}
              description={description}
              image={image}
              image_webp={image_webp}
            />
          ))
          }
        </div>}
        {!simply && <div>
          {programData.map(({ id, title, description, description2,  tags, steps }) => (
            <ProgramCard
              key={id}
              title={title}
              description={description}
              description2={description2}
              tags={tags}
              steps={steps}
              className={styles.program__card}
              fewTagsCount={id === 1}
            />
          ))}
        </div>}
      </div>
    </section>
  )
}

export default React.memo(Program);
