import { ScrollCompType } from "src/components/ui/ScrollComp/ScrollComp";
import man0_webp from "src/assets/images/targetAudience/person_1.webp";
import man0_png from "src/assets/images/targetAudience/person_1.webp";
import item0_webp from "src/assets/images/targetAudience/item_1.webp";
import item0_png from "src/assets/images/targetAudience/item_1.png";
import man1_webp from "src/assets/images/targetAudience/person_2.webp";
import man1_png from "src/assets/images/targetAudience/person_2.png";
import item1_webp from "src/assets/images/targetAudience/item_2.webp";
import item1_png from "src/assets/images/targetAudience/item_2.png";
import man2_webp from "src/assets/images/targetAudience/person_3.webp";
import man2_png from "src/assets/images/targetAudience/person_3.png";
import item2_webp from "src/assets/images/targetAudience/item_3.webp";
import item2_png from "src/assets/images/targetAudience/item_3.png";

const targetAudienceData: ScrollCompType[] = [
  {
    id: 0,
    title: 'Новичков',
    description: 'Кто сейчас находится в поиске работы в IT, хочет сменить профессию.',
    color: 'lightviolet',
    man_webp: man0_webp,
    man_png: man0_png,
    item_webp: item0_webp,
    item_png: item0_png,
  },
  {
    id: 1,
    title: 'Начинающих дизайнеров',
    description: 'Juniors, которые хотят быстро и\u00a0эффективно прокачаться в\u00a0профессии.',
    color: 'lightred',
    man_webp: man1_webp,
    man_png: man1_png,
    item_webp: item1_webp,
    item_png: item1_png,
  },
  {
    id: 2,
    title: 'Работающих в IT',
    description: 'Кому необходимы компетенции в\u00a0UX на\u00a0текущем месте работы.',
    color: 'darkblue',
    man_webp: man2_webp,
    man_png: man2_png,
    item_webp: item2_webp,
    item_png: item2_png,
  },
]

export default targetAudienceData;
