import React, { useState, useEffect, useCallback, memo } from 'react';
import Button from 'src/components/ui/Button'
import Text from 'src/components/ui/Text/Text'
import TextItem from 'src/components/ui/TextItem/TextItem'
import Sprite from 'src/assets/images/icons.svg'
import cn from 'classnames'

import * as styles from './SectionResume.module.scss';

import Avatar_png from 'src/assets/images/Avatar.png'
import Avatar_webp from 'src/assets/images/Avatar.webp'

const textItems = ['User Stories', 'User Story Map', 'User Journey Map', 'Интерактивный прототип', 'Юзабилити-тестирование']
const imgItems = [
  {
    img: '#figma',
    color: '#fff',
  },
  {
    img: `#miro`,
    color: '#FFD02F',
    type: 'small'
  },
  {
    img: `#gdoc`,
    color: '#fff',
  },
  {
    img: `#gtable`,
    color: '#fff',
  },
  {
    img: `#gpres`,
    color: '#fff',
  },
]

const SectionResume: React.FC = () => {
  const [buttonText, setButtonText] = useState('Хочу научиться');

  const scrollToForm = useCallback(() => {
    document.querySelector('#doubtsCirles')?.scrollIntoView({
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      window.innerWidth < 375 ? setButtonText('Записаться') : setButtonText('Хочу научиться')
    })
  }, [])

  return (
    <div className={styles.sectionResume}>
      <div className={styles.sectionResume__container}>
        <div className={styles.sectionResume__item}>
          <Text element="h2" className={styles.sectionResume__title}>Ваше резюме после обучения</Text>
          <div className={styles.sectionResume__learner}>
            <picture className={styles.sectionResume__learnerAvatar}>
              <source srcSet={Avatar_png} type='image/png' />
              <source srcSet={Avatar_webp} type="image/webp" />
              <img src={Avatar_png} alt="Аватар" />
            </picture>
            <div className={styles.sectionResume__learnerData}>
              <Text element="p" className={styles.sectionResume__learnerTitle}>UX-дизайнер</Text>
              <Text element="p" className={styles.sectionResume__learnerDescription}>Проектирую интерфейсы на основе: открытых источников, опросов, интервью и тестирования с пользователями.</Text>
            </div>
          </div>
          <div className={styles.sectionResume__skills}>
            <ul className={styles.sectionResume__skillsList}>
              <li><Text element="span" className={styles.sectionResume__skillsListItem}>Понимаю процесс работы над продуктом и знаю его основные этапы.</Text></li>
              <li><Text element="span" className={styles.sectionResume__skillsListItem}>Знаю, как готовиться и проводить брифинг клиента.</Text></li>
              <li><Text element="span" className={styles.sectionResume__skillsListItem}>Умею определять группы пользователей в зависимости от их задач.</Text></li>
              <li><Text element="span" className={styles.sectionResume__skillsListItem}>Могу смоделировать путь пользователя с помощью CJM/UJM.</Text></li>
              <li><Text element="span" className={styles.sectionResume__skillsListItem}>Составляю карту разрыва (Gap Map) для выявления проблем при взаимодействии с продуктом.</Text></li>
              <li><Text element="span" className={styles.sectionResume__skillsListItem}>Использую методику How Might We для первичной генерации идей.</Text></li>
              <li><Text element="span" className={styles.sectionResume__skillsListItem}>Умею отбирать идеи с помощью ранжирования, диаграммы Венна, Product Evolution Canvas.</Text></li>
              <li><Text element="span" className={styles.sectionResume__skillsListItem}>Знаю, как подготовить, провести опрос, обработать его результаты.</Text></li>
              <li><Text element="span" className={styles.sectionResume__skillsListItem}>Знаю, как подготовиться и провести юзабилити-тестирование.</Text></li>
            </ul>
          </div>
          <Button classText={styles.sectionResume__textButton} className={styles.sectionResume__button} disabled={false} onClick={scrollToForm}>{buttonText}</Button>
        </div>
        <div className={styles.sectionResume__skillBlock}>
          <div className={styles.sectionResume__hardSkills}>
            <Text element="h3" className={styles.sectionResume__hardSkillsTitle}>Hard skills</Text>
            <div className={styles.sectionResume__hardSkillsItems}>
              {textItems.map(item => (
                <TextItem item={item} key={item}/>
              ))}
            </div>
          </div>
          <div className={styles.sectionResume__resources}>
            <Text element="h3" className={styles.sectionResume__resourcesTitle}>Вы будете использовать</Text>
            <div className={styles.sectionResume__resourcesItems}>
              {imgItems.map(item => (
                <div className={cn(styles.sectionResume__resourcesItem, item.type==='small' && styles.sectionResume__resourcesItem_small)} style={{backgroundColor: item.color}} key={item.img}>
                  <svg>
                    <use href={`${Sprite}${item.img}`} />
                  </svg>
                </div>
              ))}

            </div>
            <Text element="span" className={styles.sectionResume__resourcesText}>Вы можете пользоваться любым удобным инструментом.</Text>
            <Text element="span" className={styles.sectionResume__resourcesText}>Текстовую информацию и табличные данные предлагаем оформлять с помощью Google Docs / Google Sheets или MS Office Word / Excel. Схемы и графики удобнее строить в Figma и Miro.
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(SectionResume);
