import React from 'react'

import './TextItem.scss'

export type itemProps = {
    item: string;
  }

const TextItem: React.FC<itemProps> = ({item}) => {

  return (
    <span className="textItem">
      <span className="textItem__item">{item}</span>
    </span>
  )
}

export default TextItem;