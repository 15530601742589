import * as styles from './PlusesCourseCard.module.scss';

import React from 'react';

import cn from 'classnames';
import Text from 'src/components/ui/Text';

type Item = {
    id: number;
    title: string;
    content: string;
    src_webp: string,
    src_png: string,
    size: string;
    color: string;
};

type PlusesCourseCardProps = {
    item: Item;
    className?: string;
};

const PlusesCourseCard: React.FC<PlusesCourseCardProps> = ({ item, className }) => {
    return(
        <div className={cn(
            styles.plusesCourseCard__block,
            styles[`plusesCourseCard__block_${item.size}`],
            styles[`plusesCourseCard__blockColor_${item.color}`],
            className
        )}>
            <div className={styles.plusesCourseCard__picWrap}>
                <picture className={styles.plusesCourseCard__pic}>
                    <source srcSet={item.src_webp} type='image/webp' />
                    <source srcSet={item.src_png} type='image/png' />
                    <img src={item.src_png} alt={item.title} />
                </picture>
            </div>
            <div>
                <Text view="card_title" element="span" className={styles.plusesCourseCard__textTitle}>{item.title}</Text>
                <Text element="span" className={styles.plusesCourseCard__textContent}>{item.content}</Text>
            </div>
        </div>
    )
}

export default React.memo(PlusesCourseCard);
