import React, { useCallback, useEffect, useRef, useState } from "react";
import * as styles from "./ScrollComp.module.scss";
import { Controller, Scene } from "react-scrollmagic";
import Text from "src/components/ui/Text/Text";
import cn from "classnames";

export type ScrollCompType = {
  id: number,
  title: string,
  description: string,
  color: string,
  man_webp: string,
  man_png: string,
  item_webp: string,
  item_png: string,
}

type ScrollCompProps = {
  content: ScrollCompType[],
  title: string,
}

const getPosition = (progress: number, variants: string[]): any => {
  if (typeof window === 'undefined' || window.innerWidth < 768) return variants[0];
  return progress === 1 ? variants[1] : variants[2];
};

const ScrollComp: React.FC<ScrollCompProps> = ({ content, title }) => {
  const [sectionHeight, setSectionHeight] = useState(0);
  const [textHeight, setTextHeight] = useState(0);
  const [multiplier, setMultiplier] = useState(0);
  const sectionRef = useRef(null);
  const textRef = useRef(null);

  const handleResize = useCallback(() => {
    if (sectionRef.current) {
      const { offsetHeight } = sectionRef.current!;
      setSectionHeight(offsetHeight);
    }
    if (textRef.current) {
      const { offsetHeight } = textRef.current!;
      setTextHeight(offsetHeight);
    }
  }, [setSectionHeight, sectionRef]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [sectionRef, setSectionHeight]);

  useEffect(() => {
    const onScroll = () => {
      setMultiplier(window.innerWidth >= 1024 ? 0.35 : 0.72);
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className={styles.scrollComp}>
      <Controller>
        <Scene triggerHook="onCenter" duration={textHeight} offset={200}>
          {/* @ts-ignore */}
          {(progress: number) => (
            <div className={cn(styles.scrollComp__titleWrapper, styles[`scrollComp__titleWrapper_${content[0].color}`])} ref={textRef}>
              <Text
                element="span"
                className={cn(styles.scrollComp__title, (progress === 1 && typeof window !== 'undefined' && window.innerWidth >= 768) && styles.scrollComp__title_hide)}
              >
                { title }
              </Text>
            </div>
          )}
        </Scene>
        {content.map((item) =>
          <Scene key={item.id} triggerHook="onCenter" duration={sectionHeight - sectionHeight * multiplier} offset={0}>
            {(progress: number) => (
              <div className={styles.scrollComp__cardContainer}>
                <div
                  style={{ position: getPosition(progress, ['static', 'fixed', 'relative']), zIndex: item.id }}
                  ref={sectionRef}
                  className={cn(styles.scrollComp__card, styles[`scrollComp__color_${item.color}`] )}
                >
                  <div
                    className={styles.scrollComp__cardContentWrapper}
                    style={{ position: getPosition(progress, ['static', 'fixed', 'sticky'])}}
                  >
                    <picture
                      style={{left: typeof window === 'undefined' || window.innerWidth < 768 ? '50%' : `calc(50% * ${progress})`}}
                      className={styles.scrollComp__pictureMan}
                    >
                      <source srcSet={item.man_webp} type='image/webp' />
                      <source srcSet={item.man_png} type='image/png' />
                      <img src={item.man_png} alt={item.title} />
                    </picture>
                    <div className={styles.scrollComp__cardContent}>
                      <Text
                        className={styles.scrollComp__cardTitle}
                        view="card_big_title"
                        align="Center"
                        element="span"
                      >{item.title}</Text>
                      <Text view="card_description" className={styles.scrollComp__cardDescription}>{item.description}</Text>
                    </div>
                    <picture
                      style={{right: typeof window === 'undefined' || window.innerWidth < 768 ? '50%' : `calc(50% * ${progress})`}}
                      className={styles.scrollComp__pictureItem}
                    >
                      <source srcSet={item.item_webp} type='image/webp' />
                      <source srcSet={item.item_png} type='image/png' />
                      <img src={item.man_png} alt={item.title} />
                    </picture>
                  </div>
                </div>
              </div>
            )}
          </Scene>
        )}
      </Controller>
    </div>
  );
}

export default ScrollComp;
