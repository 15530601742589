import React, { FC, memo, useState, useCallback } from 'react';
import cn from 'classnames';

import * as styles from './ProgramCard.module.scss';

import Text from 'src/components/ui/Text';
import Badge, { BadgeVariant } from 'src/components/ui/Badge';
import ProgramStep from './components/ProgramStep';
import BaseCollapse from 'src/components/ui/BaseCollapse';
import ButtonCollapse from 'src/components/ui/ButtonCollapse';

type Tag = {
  id: string;
  text: string;
  variant: BadgeVariant;
}

type Steps = {
  id: string;
  image: string;
  text: React.ReactNode;
};

interface Props {
  title: string;
  description: string;
  description2?: string;
  tags: Tag[];
  steps: Steps[];
  className?: string;
  fewTagsCount?: boolean,
}

const ProgramCard: FC<Props> = ({ title, description, description2, tags, steps, className, fewTagsCount = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cn(
      styles.programCard,
      className,
      {
        [styles.programCard_opened]: isOpen,
        [styles.programCard_fewTags]: fewTagsCount,
      }
    )} onClick={() => setIsOpen(!isOpen)}>
      <BaseCollapse
        header={
          <div className={styles.programCard__header}>
            <div className={styles.programCard__textWrap}>
              <Text element={'h3'} weight={'400'} className={styles.programCard__title}>{title}</Text>
              <Text element={'p'} className={styles.programCard__description}>{isOpen ? description2 : description}</Text>
            </div>

            <div className={styles.programCard__tags}>
              {tags.map(({ id, text, variant }) => (
                <Badge
                  key={id}
                  variant={variant}
                  className={styles.programCard__tagsItem}
                >
                  {text}
                </Badge>
              ))}
            </div>

            <ButtonCollapse isOpen={isOpen} onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }} className={styles.programCard__btn} aria-label={`Развернуть секцию ${title}`} />
          </div>
        }
        isOpen={isOpen}
      >
        <div className={styles.programCard__steps}>
          {steps.map(({ id, image, text }) => (
            <ProgramStep
              key={id}
              image={image}
              className={styles.programCard__stepsItem}
            >
              {text}
            </ProgramStep>
          ))}
        </div>
      </BaseCollapse>
    </div>
  )
};

export default memo(ProgramCard);
