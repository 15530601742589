// extracted by mini-css-extract-plugin
export var formRegistration = "FormRegistration-module--formRegistration--Tb6c1";
export var formRegistration__wrapper = "FormRegistration-module--formRegistration__wrapper--2C7K0";
export var formRegistration__wrapper_active = "FormRegistration-module--formRegistration__wrapper_active--1GQkz";
export var formRegistration__title = "FormRegistration-module--formRegistration__title--2kGlc";
export var formRegistration__text = "FormRegistration-module--formRegistration__text--2jmSK";
export var formRegistration__form = "FormRegistration-module--formRegistration__form--2aeja";
export var formRegistration__personalText = "FormRegistration-module--formRegistration__personalText--2bpPw";
export var formRegistration__info = "FormRegistration-module--formRegistration__info--1MlKe";
export var formRegistration__infoValue = "FormRegistration-module--formRegistration__infoValue--AT1uk";
export var formRegistration__infoValue_black = "FormRegistration-module--formRegistration__infoValue_black--D0fID";
export var formRegistration__infoValue_green = "FormRegistration-module--formRegistration__infoValue_green--1gR51";
export var formRegistration__highlight = "FormRegistration-module--formRegistration__highlight--20OvC";
export var formRegistration__label = "FormRegistration-module--formRegistration__label--3ee-D";
export var formRegistration__personalInformation = "FormRegistration-module--formRegistration__personalInformation--oaq3N";
export var formRegistration__footer = "FormRegistration-module--formRegistration__footer--a9V1r";
export var formRegistration__columnFooter = "FormRegistration-module--formRegistration__columnFooter--9GRu0";
export var formRegistration__link = "FormRegistration-module--formRegistration__link--3NNLJ";
export var formRegistration__btn = "FormRegistration-module--formRegistration__btn--A_LKE";
export var formRegistration__columnBtn = "FormRegistration-module--formRegistration__columnBtn--c58Ra";