import React, { useCallback, useState } from "react";
import * as styles from "./QuestionItem.module.scss";
import BaseCollapse from "src/components/ui/BaseCollapse";
import ButtonCollapse from "src/components/ui/ButtonCollapse";
import cn from "classnames";

type QuestionItemProps = {
  text: string;
  subText: string;
};

const QuestionItem: React.FC<QuestionItemProps> = ({ text, subText }) => {
  const [isOpened, setIsOpened] = useState(false);
  const toggleIsOpened = useCallback(() => setIsOpened((prev) => !prev), [])
  return (
    <li onClick={toggleIsOpened} className={styles.questionItem__question}>
      <BaseCollapse
        header={
          <div className={styles.questionItem__item}>
            <p className={styles.questionItem__text}>{text}</p>
            <ButtonCollapse isOpen={isOpened} onClick={(e) => {
              e.stopPropagation()
              toggleIsOpened()
            }} />
          </div>
        }
        isOpen={isOpened}
      >
        <p className={styles.questionItem__subtext}>{subText}</p>
      </BaseCollapse>
    </li>
  );
};

export default React.memo(QuestionItem);
