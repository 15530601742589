import React, { useState } from 'react';

import * as styles from './SuccessfulBid.module.scss';
import cn from 'classnames';
import Text from 'src/components/ui/Text';
import Button from 'src/components/ui/Button';

import pic_png0 from 'src/assets/images/man-blue-head.png';
import pic_png1 from 'src/assets/images/man-with-time.png';
import pic_png2 from 'src/assets/images/man-orange-blazer.png';
import pic_png3 from 'src/assets/images/man-white-t-shirt.png';
import pic_png4 from 'src/assets/images/girl-in-glass.png';
import pic_png5 from 'src/assets/images/man-in-glass.png';
import pic_png6 from 'src/assets/images/girl-blue-head.png';

import pic_webp0 from 'src/assets/images/man-blue-head.webp';
import pic_webp1 from 'src/assets/images/man-with-time.webp';
import pic_webp2 from 'src/assets/images/man-orange-blazer.webp';
import pic_webp3 from 'src/assets/images/man-white-t-shirt.webp';
import pic_webp4 from 'src/assets/images/girl-in-glass.webp';
import pic_webp5 from 'src/assets/images/man-in-glass.webp';
import pic_webp6 from 'src/assets/images/girl-blue-head.webp';

import tgImage_png from 'src/assets/images/tg.png'
import tgImage_webp from 'src/assets/images/tg.webp'


const IMAGES = [
    {
        pic_png: pic_png0,
        pic_webp: pic_webp0,
        id: 'first',
    },
    {
        pic_png: pic_png1,
        pic_webp: pic_webp1,
        id: 'second',
    },
    {
        pic_png: pic_png2,
        pic_webp: pic_webp2,
        id: 'third',
        small: true,
    },
    {
        pic_png: pic_png3,
        pic_webp: pic_webp3,
        id: 'fourth',
    },
    {
        pic_png: pic_png4,
        pic_webp: pic_webp4,
        id: 'fifth',
    },
    {
        pic_png: pic_png5,
        pic_webp: pic_webp5,
        id: 'sixth',
    },
    {
        pic_png: pic_png6,
        pic_webp: pic_webp6,
        id: 'seventh',
        small: true,
    },
]

type successfullType = {
    isSended: (newValue: boolean) => void;
}

const SuccessfulBid: React.FC<successfullType> = ({isSended}) => {

    return (
        <div className={styles.successfulBid}>
            <div className={styles.successfulBid__cirles}>
                {
                    IMAGES.map(item => (
                        <picture id={item.id} className={cn(styles.successfulBid__icon, styles[`successfulBid__icon_${item.id}`], item.small && styles.successfulBid__icon_small )}>
                            <source srcSet={item.pic_png} type="image/png" />
                            <source srcSet={item.pic_webp} type="image/webp" />
                            <img src={item.pic_png} alt={item.pic_png} />
                        </picture>
                    ))
                }
            </div>
            <div className={styles.successfulBid__contentWrapper}>
                <div className={styles.successfulBid__circle}>
                  <picture className={styles.successfulBid__tgImage}>
                    <source srcSet={tgImage_png} type="image/png" />
                    <source srcSet={tgImage_webp} type="image/webp" />
                    <img src={tgImage_png} alt={tgImage_png} />
                  </picture>
                </div>
                <Text element="h1" className={styles.successfulBid__title}>
                  Подписывайтесь на&nbsp;Телеграм&nbsp;-&nbsp;канал
                </Text>
                <Text element="p" className={styles.successfulBid__description}>
                  Заявка успешно принята. Не пропустите важные новости в нашем Телеграм-канале.
                </Text>
                <Button
                  className={styles.successfulBid__buttonBack}
                  classText={styles.successfulBid__buttonBackText}
                  onClick={() => isSended(false)}
                  element='a'
                  target = '_blank'
                  href='https://t.me/pixel_map'
                >
                  Подписаться
                </Button>
            </div>
        </div>
    );
}

export default SuccessfulBid;
