import React, {FormEvent, memo, useCallback, useState} from 'react';

import * as styles from './Doubts.module.scss';
import cn from 'classnames';
import Text from 'src/components/ui/Text';
import Input from 'src/components/ui/Input';
import Button from 'src/components/ui/Button';
import { CSSTransition } from 'react-transition-group';

import SuccessfulBid from 'src/components/SuccessfulBid';

import pic_png0 from 'src/assets/images/man-blue-head.png';
import pic_png1 from 'src/assets/images/man-with-time.png';
import pic_png2 from 'src/assets/images/man-orange-blazer.png';
import pic_png3 from 'src/assets/images/man-white-t-shirt.png';
import pic_png4 from 'src/assets/images/girl-in-glass.png';
import pic_png5 from 'src/assets/images/man-in-glass.png';
import pic_png6 from 'src/assets/images/girl-blue-head.png';

import pic_webp0 from 'src/assets/images/man-blue-head.webp';
import pic_webp1 from 'src/assets/images/man-with-time.webp';
import pic_webp2 from 'src/assets/images/man-orange-blazer.webp';
import pic_webp3 from 'src/assets/images/man-white-t-shirt.webp';
import pic_webp4 from 'src/assets/images/girl-in-glass.webp';
import pic_webp5 from 'src/assets/images/man-in-glass.webp';
import pic_webp6 from 'src/assets/images/girl-blue-head.webp';
import Popup from "src/components/ui/Popup/Popup";
import PopupPersonalData from "src/components/PopupPersonalData";
import mail from "src/api/mail";

const IMAGES = [
    {
        pic_png: pic_png0,
        pic_webp: pic_webp0,
        id: 'first',
    },
    {
        pic_png: pic_png1,
        pic_webp: pic_webp1,
        id: 'second',
    },
    {
        pic_png: pic_png2,
        pic_webp: pic_webp2,
        id: 'third',
        small: true,
    },
    {
        pic_png: pic_png3,
        pic_webp: pic_webp3,
        id: 'fourth',
    },
    {
        pic_png: pic_png4,
        pic_webp: pic_webp4,
        id: 'fifth',
    },
    {
        pic_png: pic_png5,
        pic_webp: pic_webp5,
        id: 'sixth',
    },
    {
        pic_png: pic_png6,
        pic_webp: pic_webp6,
        id: 'seventh',
        small: true,
    },
]

const Doubts: React.FC = () => {
  const [isOpenPolicy, setIsOpenPolicy] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isSend, setIsSend] = useState<boolean>(false);
  const [isRequest, setIsRequest] = useState(false);

  const onClosePolicyPopup = useCallback(() => setIsOpenPolicy(false), []);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsRequest(true);
    mail({
      email, name, type: 'module',
    }).then(() => setIsSend(true)).finally(() => setIsRequest(false));
  };

  return (
    <div className={styles.doubts} id="registration">
      <div className={styles.doubts__cirles}>
        {
          IMAGES.map(item => (
            <picture key={item.id} id={item.id} className={cn(styles.doubts__icon, styles[`doubts__icon_${item.id}`], item.small && styles.doubts__icon_small )}>
              <source srcSet={item.pic_webp} type="image/webp" />
              <source srcSet={item.pic_webp} type="image/webp" />
              <img src={item.pic_png} />
            </picture>
          ))
        }
      </div>
      <form className={styles.doubts__contentWrapper} onSubmit={onSubmit} id="doubtsCirles">
        <Text align='Center' className={styles.doubts__title}>
          Хотите стать первоклассным UX&nbsp;-&nbsp;дизайнером?
        </Text>
        <Text view="card_description" align='Center' className={styles.doubts__description}>
          Оставьте заявку на получение первых двух модулей из курса — бесплатно
        </Text>
        <div className={styles.doubts__inputWrapper}>
          <Input value={name} onChange={setName} className={styles.doubts__input} placeholder='Ваше имя' name="name" />
          <Input value={email} onChange={setEmail} className={styles.doubts__input} placeholder='Введите e-mail' name="email" />
        </div>
        <Text align='Center' view="sub" className={styles.doubts__agreement}>
          Нажимая на кнопку, я соглашаюсь на&nbsp;
          <a
            href="#popup"
            onClick={(e) => {
              e.preventDefault();
              setIsOpenPolicy(true);
            }}
          >
            обработку&nbsp;персональных&nbsp;данных
          </a>
        </Text>
        <Button
          className={styles.doubts__button}
          disabled={!/(.+)@(.+)/.test(email) || !name}
          isLoading={isRequest}
        >
          Записаться
        </Button>
      </form>

      <Popup onClose={onClosePolicyPopup} open={isOpenPolicy}>
        <PopupPersonalData type={0} />
      </Popup>

      {isSend && (
        <CSSTransition
          in={isSend}
          timeout={500}
          classNames={styles.doubts__successSendWrap}
        >
          <div className={styles.doubts__successSend}>
            <SuccessfulBid isSended={setIsSend}/>
          </div>
        </CSSTransition>)}
    </div>
  );
}

export default memo(Doubts);
